<div class="card">
    <img src="../../assets/programme.jpg" class="card-img-top" alt="..." style="height: 35vh; object-fit: cover;">
    <div class="card-body">
        <h5 class="card-title">Programme</h5>
        <p class="card-text" style="font-size: small;">View Programme Details</p>
        <button type="button" class="btn btn-secondary" style="font-size: small;" data-toggle="modal" data-target="#programmeModal">Go</button>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="programmeModal" tabindex="-1" role="dialog" aria-labelledby="programmeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <h3 class="modal-title" id="programmeModalLabel">Programme</h3>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div>
              <div class="row">
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <p>Once again, we are organising a full programme for our event.</p>
                </div>
              </div>
              <div class="row">
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <p>There will be 3 nights of professional entertainment on Friday, Saturday and Sunday plus daytime activities arranged for all ages, including cash bingo, quizzes, a cream tea, kids club, bacon baps and more.</p>
                </div>
              </div>
              <div class="row">
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <p>We have arranged our event to coincide with The Cotswold Show and Food Festival which is being held on the weekend of 6th/7th July.</p>
                </div>
              </div>
              <div class="row">
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <p>Definitely a country show not to be missed and the show tickets can be obtained on line.</p>
                </div>
              </div>
              <div class="row">
                <div>
                  <img class="thumbnail-img" src="../../assets/CotswoldShowPoster.jpeg" style="max-width: 50%; height: auto; object-fit: cover;">
                </div>
              </div>
              <!-- <div class="row">
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <img src="../../assets/jersey best xerox.jpg"alt="..." style="height: 35vh; object-fit: cover;">
                  <img src="../../assets/onevisionoffreddie.jpg" alt="..." style="height: 35vh; object-fit: cover;">
                  <img src="../../assets/Phat Gary Poster.jpg" alt="..." style="height: 35vh; object-fit: cover;">
                  <img src="../../assets/sally vinyl new.jpg" alt="..." style="height: 35vh; object-fit: cover;">
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>