<div class="card">
    <img src="../../assets/booking.jpg" class="card-img-top" alt="..." style="height: 35vh; object-fit: cover;">
    <div class="card-body">
        <h5 class="card-title">Booking</h5>
        <p class="card-text" style="font-size: small;">View Booking Details</p>
        <button type="button" class="btn btn-secondary" style="font-size: small;" data-toggle="modal" data-target="#bookingModal">Go</button>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="bookingModal" tabindex="-1" role="dialog" aria-labelledby="bookingModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <h3 class="modal-title" id="bookingModalLabel">Booking</h3>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h5 class="modal-title">How to book the 2024 South West Centres Rally</h5>
                  <hr>
                </div>
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <!--<p>Information will be added here once finalised.</p>-->
                   <p>Deposits and/or full payment to be sent with the Booking Form to the South West Centres Treasurer.</p>
                  <p>Outstanding balances to be paid in full by the end of May 2024</p>
                  <p>A full breakdown of all fees are on the booking form.</p>
                  <p>Bookings can now be made by emailing a completed booking form to: <a href="mailto:Treasurer@southwestcentres.co.uk">South West Centres Treasurer</a></p>
                  <p>To download and print the Booking Form please click this link :-</p>
                  <p><a href="../../assets/booking-forms/Booking form 2024.pdf" target="window_blank">Booking Form (PDF, A4)</a></p>
                  <p><a href="../../assets/booking-forms/Booking form 2024.docx" target="window_blank">Booking Form (Word, A4)</a></p>
                  <!--<p>Please make all cheques payable to "South West Centres Rally". Post-dated cheques will not be accepted.</p>
                  <p>A "Unit" comprises a car + caravan, or a motorhome + car, 2 Adults and up to 3 Children.
                    Any extras will be required to pay the "Additional Person(s)" charge - £10.00 each</p>
                  <p>The deposit and/or full payment can be made via online bank transfer using sortcode 40-08-28 account 31632310
                    Please quote your surname and the last four digits of your CAMC membership number as the reference so that bookings can be allocated correctly.</p>
                  <p>All cancellations will be subject to the following conditions:</p>
                  <p>up to the 15th June 2022 - Full refund
                    16th June 2022 onwards (including non-arrivals) - non-refundable</p>
                  <p>Cotswold Show Tickets can be purchased online with a 20% discount. <a href="https://Cotswoldshow.ticketsrv.co.uk/tickets" target="_blank">Buy Tickets Here</a></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>