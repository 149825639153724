import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommitteeMember } from '../interfaces/CommitteeMember';
import { Centre } from '../interfaces/Centre';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor() { }
  getCommitteeMembers(): Observable<CommitteeMember[]>{
    const committee: CommitteeMember[] = [
      {position: "Chairman", name: "Mr Alan Parkinson", emailAddress: "chairman@southwestcentres.co.uk"},
      {position: "Vice Chairman", name: "Mr Paul Hopkins", emailAddress: "vicechairman@southwestcentres.co.uk"},
      {position: "Secretary", name: "Mr Phil Sambrook", emailAddress: "secretary@southwestcentres.co.uk"},
      {position: "Treasurer", name: "Mrs Angela Sambrook", emailAddress: "treasurer@southwestcentres.co.uk"},
      {position: "Minutes Secretary", name: "Mrs Ann Parkinson", emailAddress: "minutessec@southwestcentres.co.uk"},
      {position: "Site Marshall", name: "Mr Mark Tolman", emailAddress: "site1@southwestcentres.co.uk"},
      {position: "Site Marshall (Electrical)", name: "Mr Nick Randell", emailAddress: "site2@southwestcentres.co.uk"},
      {position: "Web Master", name: "Mr Jack Pallant", emailAddress: "webmaster@southwestcentres.co.uk"}
    ];

    return of(committee);
  }

  getCentres(): Observable<Centre[]>{
    const centres: Centre[] = [
      {name: "Avon Centre", rep: [{name:"Mrs Jan Trott", emailAddress:"avonrep1@southwestcentres.co.uk"}]},
      {name: "Devon & Cornwall Centre", rep: [{name:"Mrs Janice Featherstone", emailAddress:"dandcrep2@southwestcentres.co.uk"},{name:"Mr Paul Featherstone", emailAddress:"dandcrep1@southwestcentres.co.uk"}]},
      {name: "East Dorset Centre", rep: [{name:"", emailAddress:""}]},
      {name: "West Dorset Centre", rep: [{name:"Mr Graham Brown", emailAddress:"wdorsetrep1@southwestcentres.co.uk"},{name:"Mrs Valerie Brown", emailAddress:"wdorsetrep2@southwestcentres.co.uk"}]},
      {name: "Gloucestershire Centre", rep: [{name:"Mr Graham Bevan", emailAddress:"glosrep1@southwestcentres.co.uk"},{name:"Ann Bevan", emailAddress:"glosrep2@southwestcentres.co.uk"}]},
      {name: "West Hampshire Centre", rep: [{name:"Mrs Emma Elsey", emailAddress:"whantsrep1@southwestcentres.co.uk"},{name:"Mr Mike Firmager", emailAddress:"whantsrep2@southwestcentres.co.uk"}]},
      {name: "IOW Centre", rep: [{name:"", emailAddress:""}]},
      {name: "Somerset Centre", rep: [{name:"", emailAddress:""}]},
      {name: "Wiltshire Centre", rep: [{name:"Mrs Liz Tolman", emailAddress:"wiltshirerep1@southwestcentres.co.uk"}]}
    ];

    return of(centres);
  }
}
