<div class="card">
    <img src="../../assets/location.jpg" class="card-img-top" alt="..." style="height: 35vh; object-fit: cover;">
    <div class="card-body">
        <h5 class="card-title">Location</h5>
        <p class="card-text" style="font-size: small;">View Location Details</p>
        <button type="button" class="btn btn-secondary" style="font-size: small;" data-toggle="modal" data-target="#locationModal">Go</button>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="locationModal" tabindex="-1" role="dialog" aria-labelledby="locationModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <h3 class="modal-title" id="locationModalLabel">Location</h3>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div>
              <div class="row">
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <p>The rally will be at Cirencester Park, Cirencester, Gloucestershire</p>

                  <p><b>DIRECTIONS TO CIRENCESTER PARK</b></p>

                  <p>Leave Cirencester ring road at the roundabout at the end of the dual carriageway onto the A419 towards Stroud.</p>

                  <p><b>ANY SIGNS TO THE CARAVAN & MOTORHOME CLUB SITE – WE ARE NOT THERE!</b></p>

                  <p>In approx. 1 1/2miles turn right into the access lane to Cirencester Park by the Estate Cottage.</p>

                  <p>(There is normally a signpost on the left hand side of the road opposite the turning advertising the next polo match to be played)</p>

                  <img class="thumbnail-img" src="../../assets/SignToTurnAt.jpeg" style="max-width: 50%; height: auto; object-fit: cover;">

                  <p>Follow the estate road, paying attention to any site arrows or speed restrictions as there will be other people in the park. Please keep an eye out for any wild animals (deer, squirrels, birds etc.) as they don’t obey traffic instructions, and can appear suddenly from any of the undergrowth.</p>

                  <p>The roadway will take you past the Polo pitches, but please take care as this road is loose chippings and not very smooth.</p>

                  <p>You will see our site at the end of the gravel track, facing the roadway.</p>
                    
                  <p>(N) 51.7113486, (W) -1.9896674 (N51° 42' 40.86", W001° 59' 22.80" ) To view in Google Earth click <a href='https://www.google.co.uk/maps/@51.7113486,-1.9896674,1130m/data=!3m1!1e3' target="_blank">Google Earth Link</a></p>

                  <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2472.1590802316996!2d-1.9829807842552!3d51.711832079669236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4871145a6b970c49%3A0x94293042469cc2b7!2sCirencester%20Park!5e0!3m2!1sen!2suk!4v1637529858396!5m2!1sen!2suk" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe></p>
                </div>
              </div>
              <div class="row">
                <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <h2>What3words</h2>

                  <p>What3words have divided the world into 3 metre squares and gave each square a unique combination of three words. It’s the easiest way to find and share exact locations.</p>
                    
                  <p><a href="https://what3words.com/crisps.study.grief" target="_blank">Gate entrance:</a></p>

                  <img src="../../assets/what3words picture - gate entrance.png" alt="..." style="height: 35vh; object-fit: cover;">

                  <p><a href="https://what3words.com/curtains.canoe.celebrate" target="_blank">Field location:</a></p>

                  <img src="../../assets/what3words picture.png" alt="..." style="height: 35vh; object-fit: cover;">

                  <p></p>

                  <img class="thumbnail-img" src="../../assets/LocationPoster.jpeg" style="max-width: 50%; height: auto; object-fit: cover;">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>