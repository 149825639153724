<div class="card">
    <img src="../../assets/contact.jpg" class="card-img-top" alt="..." style="height: 35vh; object-fit: cover;">
    <div class="card-body">
        <h5 class="card-title">Contact</h5>
        <p class="card-text" style="font-size: small;">View Contact Details</p>
        <button type="button" class="btn btn-secondary" style="font-size: small;" data-toggle="modal" data-target="#contactModal">Go</button>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <h3 class="modal-title" id="contactModalLabel">Contact</h3>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body row">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <h5 class="modal-title">To email any Member please just click on their name</h5>
            <hr>
          </div>
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div class="row">
              <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 border-right border-secondary'>
                <div class="row mb-2" *ngFor='let member of committee'>
                  <div class='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'><strong>{{member.position}}</strong>: </div>
                  <div class='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'><a href = "mailto:{{member.emailAddress}}">{{member.name}}</a></div>
                </div>
              </div>
              <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6' style="text-align: left;">
                <div class="row mb-2" *ngFor='let centre of centres'>
                  <div class='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'><strong>{{centre.name}}</strong>: </div>
                  <div class='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                    <div class="row" *ngFor='let rep of centre.rep'>
                      <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                        <a href = "mailto:{{rep.emailAddress}}">{{rep.name}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
        <div class="modal-footer">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>