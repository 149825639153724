<div class="card">
    <img src="../../assets/about.jpg" class="card-img-top" alt="..." style="height: 35vh; object-fit: cover;">
    <div class="card-body">
        <h5 class="card-title">About</h5>
        <p class="card-text" style="font-size: small;">View details about South West Centres</p>
        <button type="button" class="btn btn-secondary" style="font-size: small;" data-toggle="modal" data-target="#aboutModal">Go</button>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="aboutModal" tabindex="-1" role="dialog" aria-labelledby="aboutModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <table class="modal-content">
        <tr class="modal-header">
          <td class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <h3 class="modal-title" id="aboutModalLabel">About</h3>
          </td>
          <td class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </td>
        </tr>
        <tr class="modal-body">
          <td>
            <table>
              <tr class="row">
                <td class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style="text-align: center;">
                  <p>This is our second event that we are running at Cirencester Park. Our first event in 2022 was a great success and we hope to make the 2024 event even better.</p>
                </td>
              </tr>
              <tr class="row">
                <td class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style="text-align: center;">
                  <p>The Cotswold Show and Food Festival will again be runningon the weekendof the 6th and 7th July 2024, which is within walking distance. No need to drive to the show so you can sample the delights of the show.</p>
                </td>
              </tr>
              <tr class="row">
                <td class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style="text-align: center;">
                  <p>Cirencester Park is within easy walking distance to Cirencester town which has plenty of shops and pubs to visit. You can also enjoy the local Cotswold Villages that surround this beautiful part of the country.</p>
                </td>
              </tr>
              <tr class="row">
                <td class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style="text-align: center;">
                  <p>NOTE: Pets are very welcome to attend this event, unfortunately though they are not permitted in the marquees</p>
                </td>
              </tr>
              <tr class="row">
                <td class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style="text-align: center;">
                  <p>Please keep visiting the website for more updates and information.</p>
                </td>
              </tr>
              <tr class="row">
                <td class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12' style="text-align: center;">
                  <img src="../../assets/SWC_2024Poster.png" alt="..." style="height: 100vh; object-fit: cover;">
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr class="modal-footer">
          <td class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </td>
        </tr>
      </table>
    </div>
  </div>