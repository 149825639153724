import { Component, OnInit } from '@angular/core';
import { ContactService } from "../services/contact.service";
import { CommitteeMember } from '../interfaces/CommitteeMember';
import { Centre } from '../interfaces/Centre';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  committee: CommitteeMember[];
  centres: Centre[];

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.contactService.getCommitteeMembers()
    .subscribe(result => this.committee = result);

    this.contactService.getCentres()
    .subscribe(result => this.centres = result);
  }

}
