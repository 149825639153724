
<div class="jumbotron">
    <div style="text-align: center;">
        <h1 class="display-4">South West Centres</h1>
        <p class="lead">A warm welcome from the South West Centres</p>
    </div>
    <div class="alert alert-primary mb-0" role="alert" style="text-align: center;">
        <!-- <h4>GREAT NEWS FOR 2022</h4> -->
      
        <h4>SOUTH WEST CENTRES EVENT at Cirencester Park 3rd July to 9th July 2024</h4>
      
        <h4>To coincide with THE COTSWOLD SHOW AND FOOD FESTIVAL 6th and 7th July 2024</h4>

        <p>NOTE: Pets are very welcome to attend this event, unfortunately though they are not permitted in the marquees</p>
    </div>
    <!-- <div class="alert alert-warning mb-0" role="alert" style="text-align: center;">
        <h3>REMINDER</h3>

        <h5>We hope you are looking forward to joining us at Cirencester Park for our 1st event. We're hoping the weather will be kind and we can enjoy the Cotswold Show and Food Festival.</h5>

        <h5>Please ensure that full payment is made by the end of May 2022.</h5>

        <h5>Once payment has been made, please email our <a href="mailto:treasurer@southwestcentres.co.uk">treasurer</a> so that your payment can be matched to your booking.</h5>

        <h5>If you would like to make any amendments to your booking like adding extra nights, bacon baps or cream teas, please get in touch and we will be happy to help.</h5>
    </div> -->
</div>


