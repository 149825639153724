<div>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
        <app-header></app-header>
      </div>
    </div>
    <div style="padding-left: 2%; padding-right: 2%;">
      <div class='row'>
        <div class='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2'>
          <app-about></app-about>
        </div>
        <div class='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2'>
          <app-programme></app-programme>
        </div>
        <div class='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2'>
          <app-contact></app-contact>
        </div>
        <div class='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2'>
          <app-booking></app-booking>
        </div>
        <div class='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2'>
          <app-location></app-location>
        </div>
        <div class='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2'>
          <app-gallery></app-gallery>
        </div>
      </div> 
    </div>
  </div>
</div>