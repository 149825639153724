<div class="card">
    <img src="../../assets/gallery.png" class="card-img-top" alt="..." style="height: 35vh; object-fit: cover;">
    <div class="card-body">
        <h5 class="card-title">Gallery</h5>
        <p class="card-text" style="font-size: small;">View Gallery</p>
        <button type="button" class="btn btn-secondary" style="font-size: small;" data-toggle="modal" data-target="#galleryModal">Go</button>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="galleryModal" tabindex="-1" role="dialog" aria-labelledby="galleryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" style="width: 100%;">
        <div class="modal-header">
          <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <h3 class="modal-title" id="galleryModalLabel">Gallery</h3>
          </div>
          <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <table>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC1.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC2.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC3.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC4.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC16.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC6.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC7.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC8.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC9.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC10.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC11.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC12.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC13.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC14.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC15.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC5.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC17.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC18.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC19.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC20.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC26.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC22.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC23.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC24.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC25.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC27.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC37.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC32.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC29.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC30.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC31.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC28.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC33.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC34.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC35.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC36.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC21.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC38.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC39.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC44.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC41.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC42.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC43.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC40.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC45.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC46.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC57.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC48.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC49.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC50.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC51.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC58.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC53.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC54.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC55.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC56.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
            <tr class="row">
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC47.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
              <td class='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3' style="text-align: center;">
                <img class="thumbnail-img" src="../../assets/gallery/SWC52.jpg" style="max-width: 100%; height: auto; object-fit: cover;">
              </td>
            </tr>
          </table>
        </div>
        <div class="modal-footer">
          <div class='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>